<template>
  <div class="page">
    <Loader v-if="loader"></Loader>
    <div v-if="!loader" class="container fixed">
      <div class="page__title_main with-margin-6">Подтверждение входа</div>
      <label class="page__title_desc with-margin">
        На ваш номер телефона был отправлен смс-код для подтверждения изменения
        данных
      </label>

      <form class="">
        <div class="inputs_code">
          <!--
                            Циклично выводим подполя для формы.
                            @keyup - событие для удаления данных
                            @input - событие для ввода данных
                            @click - для установки фокуса на пустую ячейку
                            value - для установки значения по умолчанию, добавлено для того
                                    чтобы избежать багов при удалении
                        -->
          <div v-for="(field, index) in numbers" :key="index" class="">
            <input
              :id="'code_input_reduct_' + index"
              ref="form"
              class="input_code"
              @click="clickFn($event, index)"
              @keyup="keyupFn($event, index)"
              @input="changeFn"
              @keypress="isNumber($event)"
              :value="symbols[index] || ''"
              type="tel"
              autocomplete="off"
              required
            />
          </div>

          <input class="hidden" v-model="pass" :maxLength="numbers" />
        </div>
      </form>

      <Button :disabled="!isValid" text="Отправить" @click="submit"></Button>
    </div>

  </div>
</template>

<script>
import Button from "@/components/common/Button.vue";
import Loader from "@/components/4laps/Loader.vue";
import { notification } from "ant-design-vue";
import { axiosSimpleWrapper } from "../../helpers/dataHelpers";
import { logDebug } from "@/helpers/logHelper";

export default {
  name: "RedactCode",
  components: { Button, Loader },
  props: {
    value: String,
    numbers: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  data() {
    return {
      deleteCount: 0,
      symbols: [],
      pass: "",
      isValid: false,
      phone: "",
      uuid: "",
      loader: true,
    };
  },

  mounted() {
    this.loader = false;
  },
  //   todo component
  created() {
    this.pass = this.value;
  },

  watch: {
    pass: {
      handler() {
        this.$emit("input", this.pass);
        // Если введен последний символ, то с помощью emit
        // вызываем событие отправки кода на сервер
        if (this.pass && this.pass.length == 4) {
          this.isValid = true;

          // todo component method
          this.$emit("send-code");
        } else {
          this.isValid = false;
        }
      },
      deep: true,
    },
    symbols(symbols) {
      // Заполняем наши ячейки для кода данными из массива
      symbols.forEach((symbol, index) => {
        this.$refs.form[index].value = symbol;
        if (this.$refs.form[index + 1]) {
          this.$refs.form[index + 1].focus();
        }
      });

      // Обновляем строку с данными которые отправляем на сервер
      this.pass = symbols.join("");
    },
  },

  methods: {
    // Обработчик клика на поле
    clickFn() {
      // Если пользователь вдруг кликнет на любой input кроме последнего в котором есть данные, его перебросило на последний не пустой input
      const focus = this.symbols.length ? this.symbols.length : 0;
      if (this.$refs.form[focus]) {
        this.$refs.form[focus].focus();
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        console.log('preventDefault')
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // Обработчик удаления символов из поля
    keyupFn(e, i) {
      if (e.key === "Backspace") {
        if (!this.deleteCount && i === 3) {
          // Условие,в котором при удалении данных сразу не переключался фокус
          this.deleteCount++;
          this.symbols.pop();
        } else {
          // При удалении данных, должен происходить фокус на предыдущий input,
          // и обнуляться в нем значение.
          this.$refs.form[i ? i - 1 : i].focus();
          this.symbols.pop();
          this.$refs.form[i ? i - 1 : i].value = "";
          this.deleteCount = 0;
        }
      }
    },

    changeFn(e) {
      // if (!e.isTrusted) {
      //   e.stopPropagation()
      // }
      // Разбиваем введенные данные на массив,
      // нужно для того чтобы можно было вставлять данные
      // с помощью copy\paste
      const valueArr = e.target.value.split("");

      // Добавляем массив введенного значения в общий массив с символами
      this.symbols.push(...valueArr);

      // Делаем проверку, если количество символов превышает количество
      // ячеек для ввода, то мы обрезаем массив
      if (this.symbols.length > this.numbers) {
        this.symbols = this.symbols.slice(0, this.numbers);
      }
    },
    submit() {
      logDebug(this.pass);
      this.loader = true;
      // this.$router.push({ path: '/info', query: { email: this.email, code: this.pass } });
      const req = {
        method: "post",
        url: "/pets/check/" + this.$route.query.uuid,
        data: {
          sms_code: this.pass,
        },
      };
      axiosSimpleWrapper(req, (response) => {
        console.log('response', response)
        if (response.data.success) {
          this.$router.push({
            path: "/redact",
            query: {
              phone: this.$route.query.phone,
              uuid: this.$route.query.uuid,
              code: this.pass,
            },
          });
        } else {
          notification.open({
            message: response.data.message,
          });
        }
        this.loader = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.page-code {
  display: flex;
  align-items: center;
  justify-content: center;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input {
    &:focus {
      border-color: #dfdfdf;
    }
  }
}

.inputs_code {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-bottom: 30px;
}

.input_code {
  width: 65px;
  height: 65px;
  border: none;
  background-color: #f5f5f5;
  border-radius: 16px;
  text-align: center;
  color: #000000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 26px;

  &:focus {
    outline: none;
    border: 1px solid #dfdfdf;
  }
  &:focus-visible {
    outline: none;
    border: 1px solid #dfdfdf;
  }

  &:focus-within {
    border-color: #dfdfdf;
  }
}
</style>
